/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable consistent-return */
/* eslint-disable no-lone-blocks */
import { getLink } from '@core_modules/cms/helpers/magezonLinkGenerator';
import { getCategoriesInfo } from '@core_modules/cms/services/graphql';
import { getResolver, setResolver } from '@helper_localstorage';
import parse from 'html-react-parser';
import Link from 'next/link';
import React, { memo } from 'react';

const DOM_NAME = 'pwalink';

const MagezonLink = (props) => {
    const { link, link_target, children } = props;
    const { data: dataCategories } = getCategoriesInfo({
        skip: !getLink(link).includes('type="category"'),
        variables: {
            ids: getLink(link).includes('type="category"') ? getLink(link).split('id="')[1].split('"')[0] : '',
        },
    });

    const handleClickProduct = async (url_key, blank) => {
        if (!blank || blank === 0) {
            const urlResolver = getResolver();
            urlResolver[`/${url_key}`] = {
                type: 'PRODUCT',
            };
            await setResolver(urlResolver);
        }
    };

    const handleClickCategory = async (url_path, id, blank) => {
        if (!blank || blank === 0) {
            const urlResolver = getResolver();
            urlResolver[`/${url_path}`] = {
                type: 'CATEGORY',
                id,
            };
            await setResolver(urlResolver);
        }
    };

    const contentLink = getLink(link);
    if (contentLink && contentLink !== '' && contentLink.includes(DOM_NAME)) {
        return parse(contentLink, {
            replace: (domNode) => {
                if (domNode.name === DOM_NAME && domNode.attribs) {
                    const {
                        type, url, title, extra, blank, url_key, id,
                    } = domNode.attribs;
                    switch (type) {
                    case 'custom':
                        return (
                            <Link
                                target={blank === true || blank === 'true' ? '_blank' : '_self'}
                                style={{ width: '100%' }}
                                href={url}
                                color="inherit"
                                underline="none"
                                prefetch={false}
                            >

                                {children || title}
                            </Link>
                        );
                    case 'product':
                        return (
                            <Link
                                href="/[...slug]"
                                as={`/${url_key + extra}`}
                                target={blank === true || blank === 'true' ? '_blank' : '_self'}
                                prefetch={false}
                                onClick={() => handleClickProduct(url_key, blank)}
                                style={{ width: '100%' }}
                            >
                                {children || title}
                            </Link>
                        );
                    case 'category':
                        return (
                            <>
                                {dataCategories
                                    && dataCategories.categories
                                    && dataCategories.categories.items
                                    && dataCategories.categories.items.length > 0 ? (
                                        <Link
                                            href="/[...slug]"
                                            as={`/${
                                                (dataCategories.categories.items[0].url_path || dataCategories.categories.items[0].url_key) + extra
                                            }`}
                                            prefetch={false}
                                            onClick={() => handleClickCategory(dataCategories.categories.items[0].url_path, id, blank)}
                                            style={{ width: '100%' }}
                                        >
                                            {children || title}
                                        </Link>
                                    ) : null}
                            </>
                        );
                    case 'page':
                        return (
                            <Link
                                href={url_key ? `/${url_key + extra}` : `/${url.split('/')[3] + extra}`}
                                prefetch={false}
                                target={blank === true || blank === 'true' ? '_blank' : '_self'}
                                style={{ width: '100%' }}
                            >
                                {children || title}
                            </Link>
                        );
                    default:
                        return null;
                    }
                }
            },
        });
    }

    if (contentLink && contentLink !== '' && !contentLink.includes(DOM_NAME)) {
        return (
            <Link href={`${contentLink}`} prefetch={false} target={link_target === '_blank' ? '_blank' : '_self'}>
                {children}
            </Link>
        );
    }
    return children;
};

const notRenderIf = (prevProps, nextProps) => prevProps.content === nextProps.link;

export default memo(MagezonLink, notRenderIf);
